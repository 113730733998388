// main group ID must be separated from the title by a single space

export const koondvaadeMainGroups: Record<string, string[]> = {
  "01 Põhitegevuse tulud": ["30", "32", "35a", "38"],
  "02 Põhitegevuse kulud": ["41", "45a", "50", "55", "60"],
  "03 PÕHITEGEVUSE TULEM:": [
    "30",
    "32",
    "35a",
    "38",
    "41",
    "45a",
    "50",
    "55",
    "60",
  ],
  "04 Investeerimistegevus": ["381", "15b", "35b", "45b", "65a", "65b"],
  "05 EELARVE TULEM:": [
    "30",
    "32",
    "35a",
    "38",
    "41",
    "45a",
    "50",
    "55",
    "60",
    "381",
    "15b",
    "35b",
    "45b",
    "65a",
    "65b",
  ],
  "06 Likviidsete varade muutus": ["100"],
  "07 Finantseerimistegevus": ["25a", "25b"],
  "08 NÕUETE JA KOHUSTUSTE SALDODE MUUTUS:": [],
  "09 EELARVE KOGUMAHT:": [
    "15b",
    "50",
    "55",
    "45b",
    "25b",
    "45a",
    "41",
    "60",
    "65b",
  ],
};

export const koondvaadeSubGroups: Record<string, string[]> = {
  "30 Maksutulud": ["3000", "3030", "3034", "3044", "3045", "3047"],
  "32 Kaupade ja teenuste müük": ["32"],
  "35a Saadud toetused": ["35200", "35201", "3500", "352"],
  "38 Muud tulud": ["38250", "38251", "38252", "38254", "3882", "3880", "3888"],
  "41 Sotsiaaltoetused": ["413"],
  "45a Muud toetused": ["4500", "452"],
  "50 Tööjõukulud": ["50"],
  "55 Majandamiskulud": ["55"],
  "60 Muud tegevuskulud": ["60"],
  "381 Põhivara müük (+)": ["381"],
  "15b Põhivara soetus (-)": ["15"],
  "35b Põhivara soetuseks saadav sihtfinantseerimine(+)": ["3502"],
  "45b Põhivara soetuseks antav sihtfinantseerimine(-)": ["4502"],
  "65a Finantstulud (+)": ["655"],
  "65b Finantstkulud (-)": ["650"],
  "100 Likviidsete varade muutus (suurenemine +, vähenemine -)": ["100"],
  "25a Kohustuste võtmine": ["2585"],
  "25b Kohustuste tasumine": ["2586"],
};

export const koondvaadeTuludChart: string[] = [
  "30",
  "25a",
  "35a",
  "35b",
  "100",
  "32",
  "381",
  "38",
];

export const koondvaadeKuludChart: string[] = [
  "15b",
  "50",
  "55",
  "45b",
  "25b",
  "45a",
  "41",
  "60",
  "65b",
];

export const tulud: Record<string, string[]> = {
  "30 Maksutulud": ["3000", "3030", "3034", "3044", "3045", "3047"],
  "32 Tulud kaupade ja teenuste müügist": ["32"],
  "35 Saadavad toetused tegevuskuludeks": ["35200", "35201", "3500", "352"],
  "38 Muud tegevustulud": ["38250", "38251", "38252", "38254", "3882", "3880", "3888"],
  "Investeerimistegevus": ["381", "3502"],
};

export const kulud: Record<string, string[]> = {
  "01 Üldised valitsussektori teenused": [
    "01110",
    "01111",
    "01112",
    "01114",
    "01120",
    "01130",
    "01210",
    "01220",
    "01310",
    "01320",
    "01330",
    "01400",
    "01500",
    "01600",
    "01700",
    "01800",
  ],
  "02 Riigikaitse": ["02100", "02200", "02300", "02400", "02500"],
  "03 Avalik kord ja julgeolek": ["03100", "03200", "03300", "03400", "03600"],
  "04 Majandus": [
    "04110",
    "04120",
    "04210",
    "04220",
    "04230",
    "04310",
    "04320",
    "04330",
    "04340",
    "04350",
    "04360",
    "04410",
    "04420",
    "04430",
    "04510",
    "04512",
    "04520",
    "04530",
    "04540",
    "04550",
    "04600",
    "04710",
    "04720",
    "04730",
    "04740",
    "04810",
    "04900",
  ],
  "05 Keskkonnakaitse": [
    "05100",
    "05101",
    "05200",
    "05300",
    "05400",
    "05500",
    "05600",
  ],
  "06 Elamu- ja kommunaalmajandus": [
    "06100",
    "06200",
    "06300",
    "06400",
    "06500",
    "06605",
  ],
  "07 Tervishoid": ["07110", "07210", "07310", "07400", "07500", "07600"],
  "08 Vaba aeg, kultuur ja religioon": [
    "08102",
    "08103",
    "08105",
    "08106",
    "08107",
    "08108",
    "08109",
    "08201",
    "08202",
    "08203",
    "08204",
    "08205",
    "08206",
    "08207",
    "08208",
    "08209",
    "08210",
    "08211",
    "08212",
    "08231",
    "08232",
    "08233",
    "08234",
    "08235",
    "08236",
    "08300",
    "08400",
    "08500",
    "08600",
  ],
  "09 Haridus": [
    "09110",
    "09210",
    "09211",
    "09212",
    "09213",
    "09220",
    "09221",
    "09222",
    "09223",
    "09300",
    "09400",
    "09500",
    "09510",
    "09600",
    "09601",
    "09602",
    "09609",
    "09700",
    "09800",
  ],
  "10 Sotsiaalne kaitse": [
    "10110",
    "10120",
    "10121",
    "10200",
    "10201",
    "10300",
    "10400",
    "10402",
    "10500",
    "10600",
    "10700",
    "10701",
    "10702",
    "10800",
    "10900",
  ],
};
