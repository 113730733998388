import { storage } from "@/firebase/firebase";
import { useAppStore } from "@/store/AppStore";

const appStore = useAppStore();
export const getLogoURL = ({
  municipalitySlug,
}: {
  municipalitySlug: string;
}): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const storageRef = storage.ref(`/logos/${municipalitySlug}`);
    storageRef
      .getDownloadURL()
      .then((url) => resolve(url))
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
};

export const setLogo = (params: {
  file: File;
  municipalitySlug: string;
}): Promise<void> => {
  const { file, municipalitySlug } = params;
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const storageRef = storage.ref(`/logos/${municipalitySlug}`);
    storageRef
      .put((file as unknown) as Blob)
      .then(() => resolve())
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
};
