import firebase from "firebase/app";

export const unPackRefs = <T>(
  docsRef: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): T[] => {
  const results: T[] = [];
  docsRef.forEach((docRef) => {
    const result = unPackRef(docRef) as T;
    if (result) results.push(result);
  });
  return results;
};

export const unPackRef = <T>(
  docRef: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
): T | null => {
  if (!docRef.exists) return null;
  const result = docRef.data() as T;
  return { ...result, uid: docRef.id };
};

export const toLocaleFR = (
  value: number,
  maximumFractionDigits = 2,
  style = "currency",
  currency = "EUR"
): string =>
  value === 0
    ? ""
    : value.toLocaleString("fr-FR", {
        maximumFractionDigits,
        style,
        currency,
      });

export const FIREBASE_ERROR_MESSAGES: Record<string, string> = {
  // Thrown if the email address is not valid.
  ["auth/invalid-email"]: "E-mail on vigane",
  // Thrown if the user corresponding to the given email has been disabled.
  ["auth/user-disabled"]: "Kasutaja ei ole aktiivne",
  // Thrown if there is no user corresponding to the given email.
  ["auth/user-not-found"]: "Kasutajat ei leitud",
  // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
  ["auth/wrong-password"]: "Sellise emaili ja parooliga kasutaja puudub",
};
