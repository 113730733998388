import { reactive, readonly } from "vue";
import {
  CollectionName,
  CSV_TYPE,
  CsvImport,
  MunicipalityDTO,
  MunicipalityGeoDTO,
  Store,
} from "@/common/types";
import { getLogoURL, setLogo } from "@/firebase/storage/logo";
import {
  addEelarveandmikFun,
  addInvesteeringudFun,
} from "@/firebase/functions/functions";
import { getAllMunicipalities, getData } from "@/firebase/db/municipality";
import { EelarveAndmik } from "@/firebase/parse/types";
import { parseInvesteeringud } from "@/firebase/parse/parse-investeeringud";
import { parseEelarveandmik } from "@/firebase/parse/parse-eelarve-andmik";
import { kulud, tulud } from "@/firebase/uploadConfig";
import {
  EelarveAndmikKoond,
  parseEelarveandmikKoondvaade,
} from "@/firebase/parseData";
import { getAllMunicipalitiesGeo } from "@/firebase/db/municipalitiesGeo";
import { ToastType, useAppStore } from "@/store/AppStore";

export interface MunicipalityStoreState {
  municipality: MunicipalityDTO | null;
  municipalityLoaded: boolean;
  municipalities: MunicipalityDTO[];
  articleFeatures: boolean;
  investeeringudRaw: CsvImport | null;
  investeeringud: EelarveAndmik | null;
  investeeringudLoaded: boolean;
  eelarveAndmikRaw: CsvImport | null;
  tulud: EelarveAndmik | null;
  tuludLoaded: boolean;
  kulud: EelarveAndmik | null;
  kuludLoaded: boolean;
  koondVaade: EelarveAndmikKoond | null;
  koondVaadeLoaded: boolean;
  logoUrl: string | null;
  municipalitiesGeo: MunicipalityGeoDTO[];
}

class MunicipalityStore implements Store<MunicipalityStoreState> {
  appStore = useAppStore();

  protected state = reactive<MunicipalityStoreState>({
    municipality: null,
    municipalityLoaded: false,
    municipalities: [],
    articleFeatures: false,
    investeeringudRaw: null,
    investeeringud: null,
    investeeringudLoaded: false,
    eelarveAndmikRaw: null,
    tulud: null,
    tuludLoaded: false,
    kulud: null,
    kuludLoaded: false,
    koondVaade: null,
    koondVaadeLoaded: false,
    logoUrl: null,
    municipalitiesGeo: [],
  });

  public getState() {
    return readonly(this.state);
  }

  public resetState() {
    this.state.municipality = null;
    this.state.municipalityLoaded = false;
    this.state.municipalities = [];
    this.state.investeeringudRaw = null;
    this.state.investeeringud = null;
    this.state.investeeringudLoaded = false;
    this.state.eelarveAndmikRaw = null;
    this.state.tulud = null;
    this.state.tuludLoaded = false;
    this.state.kulud = null;
    this.state.kuludLoaded = false;
    this.state.koondVaade = null;
    this.state.koondVaadeLoaded = false;
    this.state.logoUrl = null;
  }

  public setArticleFeatures(active: boolean, changeData = true) {
    this.state.articleFeatures = active;
    if (changeData) this.setEelarveAndmik(this.state.eelarveAndmikRaw);
  }

  public async setMunicipality(municipalitySlug: string | undefined) {
    this.resetState();
    if (!municipalitySlug) return;
    try {
      const [
        municipalities,
        investeeringud,
        eelarveAndmik,
        logoURL,
      ] = await Promise.allSettled([
        getAllMunicipalities(),
        getData<CsvImport>({
          collection: CollectionName.INVESTEERINGU,
          document: municipalitySlug,
        }),
        getData<CsvImport>({
          collection: CollectionName.EELARVEANDMIK,
          document: municipalitySlug,
        }),
        getLogoURL({ municipalitySlug }),
      ]);
      if (municipalities.status === "fulfilled") {
        this.state.municipalities = municipalities.value;
        this.state.municipality =
          this.state.municipalities.find(
            (municipality) => municipality.uid === municipalitySlug
          ) ?? null;
        this.state.municipalityLoaded = true;
      } else if (municipalities.status === "rejected") {
        this.appStore.addToast({
          toastType: ToastType.ERROR,
          title: "Miskit läks valesti",
          message: municipalities.reason.message,
        });
      }

      if (investeeringud.status === "fulfilled") {
        this.setData({
          csvType: CSV_TYPE.INVESTEERINGU,
          data: investeeringud.value,
        });
      } else if (investeeringud.status === "rejected") {
        this.appStore.addToast({
          toastType: ToastType.ERROR,
          title: "Miskit läks valesti",
          message: investeeringud.reason.message,
        });
      }

      if (eelarveAndmik.status === "fulfilled") {
        this.setData({
          csvType: CSV_TYPE.EELARVEANDMIK,
          data: eelarveAndmik.value,
        });
      } else if (eelarveAndmik.status === "rejected") {
        this.appStore.addToast({
          toastType: ToastType.ERROR,
          title: "Miskit läks valesti",
          message: eelarveAndmik.reason.message,
        });
      }

      if (logoURL.status === "fulfilled") {
        this.state.logoUrl = logoURL.value;
      }
    } catch (e) {
      this.appStore.addToast({
        toastType: ToastType.ERROR,
        title: "Miskit läks valesti",
        message: e.message,
      });
    }
  }

  public setData(params: { csvType: CSV_TYPE; data: CsvImport | null }) {
    const { csvType, data } = params;
    if (csvType === CSV_TYPE.EELARVEANDMIK) this.setEelarveAndmik(data);
    else if (csvType === CSV_TYPE.INVESTEERINGU) this.setInvesteeringud(data);
  }

  public setInvesteeringud(data: CsvImport | null) {
    this.state.investeeringudRaw = data;
    this.state.investeeringud = parseInvesteeringud(data);
    this.state.investeeringudLoaded = true;
  }

  public setEelarveAndmik(data: CsvImport | null) {
    this.state.eelarveAndmikRaw = data;
    this.state.tulud = parseEelarveandmik({
      csvData: data,
      config: tulud,
      showArticleFeatures: this.state.articleFeatures,
    });
    this.state.tuludLoaded = true;
    this.state.kulud = parseEelarveandmik({
      csvData: data,
      config: kulud,
      showArticleFeatures: this.state.articleFeatures,
    });
    this.state.kuludLoaded = true;
    this.state.koondVaade = parseEelarveandmikKoondvaade({
      csvData: data,
      showArticleFeatures: this.state.articleFeatures,
    });
    this.state.koondVaadeLoaded = true;
  }

  public async uploadData(params: { csvType: CSV_TYPE; data: CsvImport }) {
    const { csvType, data } = params;
    try {
      switch (csvType) {
        case CSV_TYPE.EELARVEANDMIK:
          await addEelarveandmikFun(data);
          break;
        case CSV_TYPE.INVESTEERINGU:
          await addInvesteeringudFun(data);
          break;
        default:
          break;
      }
      this.setData({ csvType, data });
    } catch (e) {
      this.appStore.addToast({
        toastType: ToastType.ERROR,
        title: "Miskit läks valesti",
        message: e.message,
      });
    }
  }

  public async uploadLogo({ file }: { file?: File | null }): Promise<boolean> {
    if (!file) return false;
    try {
      const municipalitySlug = this.state.municipality?.uid;
      if (!municipalitySlug) return false;
      await setLogo({ file, municipalitySlug });
      this.state.logoUrl = await getLogoURL({ municipalitySlug });
      return true;
    } catch (e) {
      this.appStore.addToast({
        toastType: ToastType.ERROR,
        title: "Miskit läks valesti",
        message: e.message,
      });
      return false;
    }
  }

  public async initMunicipalities() {
    await Promise.allSettled([
      this.loadMunicipalitiesGeo(),
      this.loadMunicipalities(),
    ]);
  }

  public async loadMunicipalitiesGeo(force = false) {
    if (!!this.state.municipalitiesGeo.length && !force) return;
    this.state.municipalitiesGeo = await getAllMunicipalitiesGeo(true);
  }

  public async loadMunicipalities(force = false) {
    if (!!this.state.municipalities.length && !force) return;
    this.state.municipalities = await getAllMunicipalities();
  }
}

const store = new MunicipalityStore();
export const useMunicipalityStore = (): MunicipalityStore => store;
