import { createApp, watchEffect } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import { firebaseAuthReady } from "@/firebase/firebase";

const initVueApp = () => {
  createApp(App).use(router).mount("#app");
};

const stop = watchEffect(() => {
  if (firebaseAuthReady.value) {
    stop();
    initVueApp();
  }
});
