
import { computed, DeepReadonly, defineComponent } from "vue";
import { Toast, useAppStore } from "@/store/AppStore";
import ToastItem from "@/components/common/utils/toast/ToastItem.vue";

export default defineComponent({
  name: "ToastList",
  components: { ToastItem },
  setup() {
    const appStore = useAppStore();
    const appState = appStore.getState();
    const toasts = computed<DeepReadonly<Toast[]>>(() => appState.toasts);
    return { toasts };
  },
});
