import { EelarveAndmik } from "@/firebase/parse/types";
import {
  getGroups,
  getParsedData,
  getTotalValues,
} from "@/firebase/parse/utils";
import { DeepReadonly } from "vue";
import { CsvImport } from "@/common/types";

// TODO: get rid
const HIDE_ZEROES = false;

export const parseEelarveandmik = (params: {
  csvData: DeepReadonly<CsvImport> | null;
  config: Record<string, string[]>;
  showArticleFeatures: boolean;
}): EelarveAndmik | null => {
  const { csvData, config, showArticleFeatures } = params;
  if (!csvData) return null;

  const groups = getGroups({
    csvData,
    showArticleFeatures,
    config,
  });

  const data = getParsedData({ groups, hideZeroes: HIDE_ZEROES });
  const totalsByCategory = data.map((e) => ({
    type: e.header.title,
    totalValue: e.elements[0].title,
    currentValue: e.elements[1].title,
  }));

  const { total: budgetTotal, currentTotal: usedBudgetTotal } = getTotalValues(
    totalsByCategory
  );

  return {
    data: data,
    totalsByCategory: totalsByCategory,
    budgetTotal,
    usedBudgetTotal,
    selectedDate: csvData.selectedDate,
  };
};
