export interface CreateUserInput {
  readonly municipalitySlug: unknown;
  readonly email: unknown;
  readonly password: unknown;
}

export interface AgreementApplicationInput {
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly personalNo: number | null;
  readonly phone: string | null;
  readonly message: string | null;
}

export interface ContactInput {
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string | null;
  readonly phone: string | null;
  readonly message: string | null;
}

export enum FunctionName {
  CREATE_USER = "createUser",
  ADD_INVESTEERINGUD = "addInvesteeringud",
  ADD_EELARVEANDMIK = "addEelarveandmik",
  ADD_AGREEMENT_APPLICATION = "addAgreementApplication",
  ADD_CONTACT = "addContact",
}
