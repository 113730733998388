import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { RouteName } from "@/router/types";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { computed } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteName.HOME,
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    meta: {
      redirectIfLoggedIn: false,
      redirectIfLoggedOut: false,
      title: "Andmik",
      metaTags: [
        {
          name: "description",
          content: "Andmiku koduleht.",
        },
        {
          property: "og:description",
          content: "Andmiku koduleht.",
        },
      ],
    },
  },
  {
    path: "/login",
    name: RouteName.LOGIN,
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: {
      redirectIfLoggedIn: true,
      redirectIfLoggedOut: false,
      title: "Andmik - Logi sisse",
      metaTags: [
        {
          name: "description",
          content: "Andmiku koduleht.",
        },
        {
          property: "og:description",
          content: "Andmiku koduleht.",
        },
      ],
    },
  },
  {
    path: "/sign-up",
    name: RouteName.SIGN_UP,
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "@/views/SignUp.vue"),
    meta: {
      redirectIfLoggedIn: true,
      redirectIfLoggedOut: false,
      title: "Andmik - Liitu",
      metaTags: [
        {
          name: "description",
          content: "Andmiku koduleht.",
        },
        {
          property: "og:description",
          content: "Andmiku koduleht.",
        },
      ],
    },
  },
  {
    path: "/:municipalitySlug",
    component: () =>
      import(
        /* webpackChunkName: "municipalityHome" */ "@/views/MunicipalityHome.vue"
      ),
    meta: {
      redirectIfLoggedIn: false,
      redirectIfLoggedOut: false,
      title: "Andmik",
      metaTags: [
        {
          name: "description",
          content: "Andmiku koduleht.",
        },
        {
          property: "og:description",
          content: "Andmiku koduleht.",
        },
      ],
    },
    children: [
      {
        path: "",
        name: RouteName.MUNICIPALITY_HOME,
        redirect: () => ({ name: RouteName.KOOND_VAADE }),
      },
      {
        path: "koondvaade",
        name: RouteName.KOOND_VAADE,
        component: () =>
          import(/* webpackChunkName: "koondvaade" */ "@/views/Koondvaade.vue"),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: false,
          title: "Koondvaade",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "tulud",
        name: RouteName.TULUD,
        component: () =>
          import(/* webpackChunkName: "tulud" */ "@/views/Tulud.vue"),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: false,
          title: "Tulud",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "kulud",
        name: RouteName.KULUD,
        component: () =>
          import(/* webpackChunkName: "kulud" */ "@/views/Kulud.vue"),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: false,
          title: "Kulud",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "investeeringud",
        name: RouteName.INVESTEERINGUD,
        component: () =>
          import(
            /* webpackChunkName: "investeeringud" */ "@/views/Investeeringud.vue"
          ),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: false,
          title: "Investeeringud",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "upload-logo",
        name: RouteName.UPLOAD_LOGO,
        component: () =>
          import(
            /* webpackChunkName: "upload-logo" */ "@/views/UploadLogo.vue"
          ),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: true,
          title: "Lae logo",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "upload-csv",
        name: RouteName.UPLOAD_CSV,
        component: () =>
          import(/* webpackChunkName: "upload-csv" */ "@/views/UploadCsv.vue"),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: true,
          title: "Lae andmed",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "juhendid",
        name: RouteName.JUHENDID,
        component: () =>
          import(/* webpackChunkName: "juhendid" */ "@/views/Juhendid.vue"),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: true,
          title: "Juhendid",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "kontakt",
        name: RouteName.KONTAKT,
        component: () =>
          import(/* webpackChunkName: "kontakt" */ "@/views/Kontakt.vue"),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: true,
          title: "Kontakt",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
      {
        path: "liitumisavaldus",
        name: RouteName.LIITUMISAVALDUS,
        component: () =>
          import(
            /* webpackChunkName: "liitumisavaldus" */ "@/views/LiitumisAvaldus.vue"
          ),
        meta: {
          redirectIfLoggedIn: false,
          redirectIfLoggedOut: true,
          title: "Liitumisavaldus",
          metaTags: [
            {
              name: "description",
              content: "Andmiku koduleht.",
            },
            {
              property: "og:description",
              content: "Andmiku koduleht.",
            },
          ],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

const municipalitiesStore = useMunicipalityStore();
const municipalitiesState = municipalitiesStore.getState();
const municipalities = computed(() => municipalitiesState.municipalities);

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const municipalitySlug = to.params.municipalitySlug;
  const municipality = municipalities.value?.find(
    (m) => m.uid === to.params.municipalitySlug
  );

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (municipalitySlug && municipality && nearestWithTitle) {
    document.title = `${municipality.name} - ${
      nearestWithTitle.meta.title as string
    }`;
  } else if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title as string;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  (nearestWithMeta.meta.metaTags as Record<string, string>[])
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
