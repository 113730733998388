<template>
  <div id="modal-wrapper" style="zIndex: 9999"/>
  <div class="font-arimo">
    <router-view />
  </div>
  <div
    v-if="loading"
    class="
      h-screen
      w-screen
      bg-gray-500 
      bg-opacity-70
      fixed
      right-0
      top-0
      overflow-hidden
      font-arimo
    "
    style="zIndex: 10000"
  >

    <Spinner />
  </div>

  <ToastList style="z-index: 19999"/>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import { firebaseApp } from "@/firebase/firebase";
import { useUserStore } from "@/store/UserStore";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/router/types";
import { UserInfo } from "@/common/types";
import Spinner from "@/components/common/Spinner.vue";
import { useAppStore } from "@/store/AppStore";
import ToastList from "@/components/common/utils/toast/ToastList.vue";

export default defineComponent({
  components: { ToastList, Spinner },
  setup() {
    const appStore = useAppStore();
    const appState = appStore.getState();
    const loading = computed(() => !!appState.loading.length);

    const userStore = useUserStore();
    const userState = userStore.getState();
    const route = useRoute();
    const router = useRouter();
    onMounted(() =>
      firebaseApp
        .auth()
        .onAuthStateChanged((user) => userStore.onAuthChange(user))
    );

    const userInfo = computed(() => userState.userInfo);
    const checkRouteForSignIn = (values: {
      newValue: UserInfo;
      oldValue: UserInfo | null | undefined;
    }) => {
      if (!route.meta.redirectIfLoggedIn) return;

      const { newValue } = values;
      const { municipalitySlug } = newValue;
      router.push({
        name: RouteName.MUNICIPALITY_HOME,
        params: { municipalitySlug },
      });
    };
    const checkRouteForSignOut = (values: {
      newValue: UserInfo | null | undefined;
      oldValue?: UserInfo | null | undefined;
    }) => {
      if (!route.meta.redirectIfLoggedOut) return;
      const municipalitySlug = values.oldValue?.municipalitySlug;
      if (municipalitySlug) {
        router.push({
          name: RouteName.MUNICIPALITY_HOME,
          params: { municipalitySlug },
        });
      } else {
        router.push({
          name: RouteName.HOME,
        });
      }
    };
    watch(
      () => userInfo.value,
      (newValue, oldValue) => {
        if (!newValue) checkRouteForSignOut({ oldValue, newValue });
        else checkRouteForSignIn({ oldValue, newValue });
      },
      { immediate: true }
    );
    return { loading };
  },
});
</script>

<style scoped>

</style>