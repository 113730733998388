<template>
  <div
    ref="root"
    class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto mb-2"
  >
    <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: check-circle -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              :class="colorClass"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm leading-5 font-medium text-gray-900">
              {{ toast.title }}
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              {{ toast.message }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              @click="closeNot"
              class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
            >
              <!-- Heroicon name: x -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { Toast, useAppStore } from "@/store/AppStore";
import { gsap } from "gsap";
export default defineComponent({
  name: "ToastItem",
  props: {
    toast: {
      type: Object as PropType<Toast>,
      required: true,
    },
  },
  setup(props) {
    const appStore = useAppStore();

    const root = ref<HTMLDivElement | null>(null);
    const tl = gsap.timeline({ defaults: { opacity: 0, ease: "slow" } });
    onMounted(() => {
      tl.from(root.value, { autoAlpha: 0, y: -30, duration: 1 });
      tl.to(root.value, { delay: 2 });
      tl.addLabel("beforeEnd");
      tl.to(root.value, { autoAlpha: 0, y: -30, duration: 1 });
      tl.to(root.value, { height: 0 }, "-=0.5");
      tl.eventCallback("onComplete", () =>
        appStore.removeToast(props.toast.toastID)
      );
    });
    const colorClass = computed(() => {
      switch (props.toast.toastType) {
        case "SUCCESS":
          return "text-green-400";
        case "WARNING":
          return "text-yellow-400";
        case "ERROR":
          return "text-red-400";
        default:
          return "text-gray-400";
      }
    });
    const closeNot = () => {
      tl.play("beforeEnd");
    };
    return { colorClass, root, closeNot };
  },
});
</script>

<style scoped></style>
