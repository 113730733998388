import { reactive, readonly } from "vue";
import { Store } from "@/common/types";

export enum ToastType {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface Toast {
  toastID: number;
  toastType: ToastType;
  title: string;
  message: string;
}

export interface AppStoreState {
  loading: number[];
  toasts: Toast[];
}

class AppStore implements Store<AppStoreState> {
  protected state = reactive<AppStoreState>({
    loading: [],
    toasts: [],
  });

  public getState() {
    return readonly(this.state);
  }

  private static getTimeStamp(): number {
    return new Date().getTime();
  }

  public addLoading(): number {
    const loadingID = AppStore.getTimeStamp();
    this.state.loading.push(loadingID);
    return loadingID;
  }

  public removeLoading(loadingID: number) {
    const loadingIndex = this.state.loading.findIndex((l) => l === loadingID);
    this.state.loading.splice(loadingIndex, 1);
  }

  public addToast(toast: Omit<Toast, "toastID">): number {
    const toastID = AppStore.getTimeStamp();
    this.state.toasts.push({
      ...toast,
      toastID,
    });
    return toastID;
  }

  public removeToast(toastID: number) {
    this.state.toasts = this.state.toasts.filter((t) => t.toastID !== toastID);
  }
}

const appStore = new AppStore();
export const useAppStore = (): AppStore => appStore;
