import { UserInfo } from "@/common/types";
import { reactive, readonly } from "vue";
import { firebaseApp } from "@/firebase/firebase";
import { createUserFun } from "@/firebase/functions/functions";
import firebase from "firebase/app";
import { ToastType, useAppStore } from "@/store/AppStore";
import { FIREBASE_ERROR_MESSAGES } from "@/firebase/utils";

export interface UserStoreState {
  userInfo: UserInfo | null;
}

class UserStore {
  private appStore = useAppStore();
  protected state = reactive<UserStoreState>({
    userInfo: null,
  });

  public getState() {
    return readonly(this.state);
  }

  public resetState() {
    this.state.userInfo = null;
  }

  public async logIn(params: { email: string; password: string }) {
    const { email, password } = params;
    this.resetState();
    const loadingID = this.appStore.addLoading();
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) =>
        this.appStore.addToast({
          toastType: ToastType.ERROR,
          title: "Miskit läks valesti",
          message: FIREBASE_ERROR_MESSAGES[err.code] ?? "Miskit läks valesti",
        })
      )
      .finally(() => this.appStore.removeLoading(loadingID));
  }

  private async userLoggedOut() {
    this.resetState();
  }

  private async userLoggedIn(user: firebase.User) {
    const token = await user.getIdTokenResult();
    this.state.userInfo = token.claims as UserInfo;
  }

  public async onAuthChange(user?: firebase.User | null) {
    if (!user) await this.userLoggedOut();
    else await this.userLoggedIn(user);
  }

  public async signUpAndLogIn(params: {
    municipalitySlug: string;
    email: string;
    password: string;
  }) {
    const { email, password, municipalitySlug } = params;
    try {
      await createUserFun({ email, password, municipalitySlug });
      await this.logIn({ email, password });
    } catch (e) {
      this.appStore.addToast({
        toastType: ToastType.ERROR,
        title: "Miskit läks valesti",
        message: e.message,
      });
    }
  }

  public async logOut() {
    const loadingID = this.appStore.addLoading();
    try {
      await firebaseApp.auth().signOut();
    } catch (e) {
      this.appStore.addToast({
        toastType: ToastType.ERROR,
        title: "Miskit läks valesti",
        message: e.message,
      });
    }

    this.appStore.removeLoading(loadingID);
  }
}

const store = new UserStore();
export const useUserStore = (): UserStore => store;
