
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { Toast, useAppStore } from "@/store/AppStore";
import { gsap } from "gsap";
export default defineComponent({
  name: "ToastItem",
  props: {
    toast: {
      type: Object as PropType<Toast>,
      required: true,
    },
  },
  setup(props) {
    const appStore = useAppStore();

    const root = ref<HTMLDivElement | null>(null);
    const tl = gsap.timeline({ defaults: { opacity: 0, ease: "slow" } });
    onMounted(() => {
      tl.from(root.value, { autoAlpha: 0, y: -30, duration: 1 });
      tl.to(root.value, { delay: 2 });
      tl.addLabel("beforeEnd");
      tl.to(root.value, { autoAlpha: 0, y: -30, duration: 1 });
      tl.to(root.value, { height: 0 }, "-=0.5");
      tl.eventCallback("onComplete", () =>
        appStore.removeToast(props.toast.toastID)
      );
    });
    const colorClass = computed(() => {
      switch (props.toast.toastType) {
        case "SUCCESS":
          return "text-green-400";
        case "WARNING":
          return "text-yellow-400";
        case "ERROR":
          return "text-red-400";
        default:
          return "text-gray-400";
      }
    });
    const closeNot = () => {
      tl.play("beforeEnd");
    };
    return { colorClass, root, closeNot };
  },
});
