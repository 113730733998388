import { DeepReadonly } from "vue";

export interface MunicipalityDTO {
  name: string;
  uid: string;
  active: boolean;
  population: number;
  area: number;
  homepage: string;
  subscribed: boolean
}

export enum CollectionName {
  INVESTEERINGU = "investeeringud",
  EELARVEANDMIK = "eelarveandmik",
  MUNICIPALITIES = "municipalities",
}

export enum CSV_TYPE {
  INVESTEERINGU = "investeeringud",
  EELARVEANDMIK = "eelarveandmik",
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export interface CsvImport {
  headers: string[];
  rows: Record<string, string>[];
  uploadTimestamp: number;
  selectedDate?: string;
  dataPoints: [string, string, string];
}

export interface UserInfo {
  readonly active: boolean;
  readonly municipalitySlug: string;
}

export enum LoaderColor {
  WHITE = "WHITE",
  blue = "blue",
  WARNING = "WARNING",
}

export interface MunicipalityGeoDTO {
  uid: string;
  type: string;
  geometry: MunicipalityGeometry;
  properties: MunicipalityGeometryProperties;
}
export interface MunicipalityGeometry {
  type: string;
  coordinates?: (((number[] | null | number)[] | null)[] | null)[] | null;
}
export interface MunicipalityGeometryProperties {
  ONIMI: string;
  OKOOD: string;
  MNIMI: string;
  MKOOD: string;
  TYYP: string;
  slug: string;
}

export interface Store<T> {
  getState(): DeepReadonly<T>;
}

export interface HomeMapMunicipalityDTO {
  geo: DeepReadonly<MunicipalityGeoDTO>;
  municipality?: DeepReadonly<MunicipalityDTO>;
}

