export enum RouteName {
  HOME = "HOME",
  MUNICIPALITY_HOME = "MUNICIPALITY_HOME",
  KOOND_VAADE = "KOOND_VAADE",
  TULUD = "TULUD",
  KULUD = "KULUD",
  INVESTEERINGUD = "INVESTEERINGUD",
  UPLOAD_LOGO = "UPLOAD_LOGO",
  UPLOAD_CSV = "UPLOAD_CSV",
  LOGIN = "LOGIN",
  SIGN_UP = "SIGN_UP",
  LIITUMISAVALDUS = "LIITUMISAVALDUS",
  JUHENDID = "JUHENDID",
  KONTAKT = "KONTAKT"
}
