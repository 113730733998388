<template>
  <div
    class="fixed inset-0 flex flex-col items-end x-4 py-2 pointer-events-none"
  >
    <ToastItem v-for="toast of toasts" :key="toast.toastID" :toast="toast" />
  </div>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent } from "vue";
import { Toast, useAppStore } from "@/store/AppStore";
import ToastItem from "@/components/common/utils/toast/ToastItem.vue";

export default defineComponent({
  name: "ToastList",
  components: { ToastItem },
  setup() {
    const appStore = useAppStore();
    const appState = appStore.getState();
    const toasts = computed<DeepReadonly<Toast[]>>(() => appState.toasts);
    return { toasts };
  },
});
</script>

<style scoped></style>
