// TODO: make global
import { EelarveAndmik, ParsedData } from "@/firebase/parse/types";
import { getParsedData, getTotalValues } from "@/firebase/parse/utils";
import { DeepReadonly } from "vue";
import { CsvImport } from "@/common/types";

const HIDE_ZEROES = false;

const titleCase = (sentence: string): string =>
  sentence[0].toUpperCase() + sentence.slice(1).toLowerCase();

export const parseInvesteeringud = (
  csvData: DeepReadonly<CsvImport> | null
): EelarveAndmik | null => {
  if (!csvData) return null;
  const { dataPoints } = csvData;
  const groups: Record<string, ParsedData> = {};
  csvData.rows
    .filter((rf) => rf.teg_ala)
    .forEach((r) => {
      const groupKey = r.teg_ala.trim();

      if (!groups[groupKey]) {
        groups[groupKey] = {
          expandable: true,
          group: groupKey,
          header: {
            title: "",
            list: [],
          },
          elements: [],
        };
        dataPoints.forEach(() => {
          groups[groupKey].elements.push({
            title: 0,
            list: [],
          });
        });
      }

      if (r.kulu_obj || r.kulu_liik) {
        // TODO when turned on
        // const prefix = r.kulu_obj ?? r.kulu_liik;
        // groups[groupKey].header.list.push(prefix + " " + r.nimi?.trim());
        groups[groupKey].header.list.push(r.nimi?.trim());
        dataPoints.forEach((kk, index) => {
          groups[groupKey].elements[index].list.push(
            parseFloat(r[kk].replace(",", "."))
          );
        });
      } else {
        groups[groupKey].header.title = r.nimi?.trim();

        dataPoints.forEach((kk, index) => {
          groups[groupKey].elements[index].title = parseFloat(
            r[kk].replace(",", ".")
          );
        });
      }
      // if (!r.kulu_obj) {
      //   groups[groupKey].header.title = r.nimi?.trim();
      //
      //   dataPoints.forEach((kk, index) => {
      //     groups[groupKey].elements[index].title = parseFloat(
      //       r[kk].replace(",", ".")
      //     );
      //   });
      // } else {
      //   groups[groupKey].header.list.push(r.nimi?.trim());
      //   dataPoints.forEach((kk, index) => {
      //     groups[groupKey].elements[index].list.push(
      //       parseFloat(r[kk].replace(",", "."))
      //     );
      //   });
      // }
    });

  for (const key in groups) {
    if (!groups[key].header.list.length) {
      delete groups[key];
    }
  }

  for (const key in groups) {
    groups[key].elements.forEach(
      (e) => (e.title = e.list.reduce((a, curr) => a + curr, 0))
    );
  }

  const data = getParsedData({ groups, hideZeroes: HIDE_ZEROES });

  const totalsByCategory = data.map((e) => ({
    type: e.header.title,
    totalValue: e.elements[0].title,
    currentValue: e.elements[1].title,
  }));

  const { total: budgetTotal, currentTotal: usedBudgetTotal } = getTotalValues(
    totalsByCategory
  );
  return {
    data: data.map((d) => ({
      ...d,
      header: {
        ...d.header,
        title: titleCase(d.header.title),
      },
    })),
    totalsByCategory: totalsByCategory,
    budgetTotal: budgetTotal,
    usedBudgetTotal: usedBudgetTotal,
    selectedDate: csvData.selectedDate,
  };
};
