import { MunicipalityGeoDTO } from "@/common/types";
import { useAppStore } from "@/store/AppStore";
import { db } from "@/firebase/firebase";
import { unPackRefs } from "@/firebase/utils";

const COLLECTION = "municipalitiesGeo";
const appStore = useAppStore();

export const getAllMunicipalitiesGeo = async (
  loader = false
): Promise<MunicipalityGeoDTO[]> => {
  const loadingID = loader ? appStore.addLoading() : null;
  const itemsRef = await db.collection(COLLECTION).get();
  if (loadingID) appStore.removeLoading(loadingID);
  return unPackRefs<MunicipalityGeoDTO>(itemsRef).map((g) => ({
    ...g,
    geometry: {
      ...g.geometry,
      coordinates: JSON.parse((g.geometry.coordinates as unknown) as string),
    },
  }));
};
