// this variable is used to show/hide rows without values in parsed data
import {
  koondvaadeKuludChart,
  koondvaadeMainGroups,
  koondvaadeSubGroups,
  koondvaadeTuludChart,
} from "@/firebase/uploadConfig";
import { ParsedData, TotalsByCategory } from "@/firebase/parse/types";
import { getGroups, getTotalValues } from "@/firebase/parse/utils";
import { DeepReadonly } from "vue";
import { CsvImport } from "@/common/types";

export interface EelarveAndmikKoond {
  readonly data: ParsedData[];
  readonly tuludChartData: TotalsByCategory[];
  readonly tuludTotal: number;
  readonly tuludCurrentTotal: number;
  readonly kuludChartData: TotalsByCategory[];
  readonly kuludTotal: number;
  readonly kuludCurrentTotal: number;
  readonly selectedDate: string | undefined;
}

export const parseEelarveandmikKoondvaade = (params: {
  csvData: DeepReadonly<CsvImport> | null;
  showArticleFeatures: boolean;
}): EelarveAndmikKoond | null => {
  const { csvData, showArticleFeatures } = params;
  if (!csvData) return null;

  const groups = getGroups({
    csvData,
    showArticleFeatures,
    config: koondvaadeSubGroups,
  });

  const mainGroups: Record<string, ParsedData> = Object.entries(
    koondvaadeMainGroups
  ).reduce((acc, curr) => {
    const [key, values] = curr;
    const splittedKey = key.split(" ");
    const mainGroupCode = splittedKey[0];
    if (mainGroupCode === "08") return acc;
    const title = splittedKey.slice(1).join(" ");
    return {
      ...acc,
      [mainGroupCode]: {
        expandable: isExpandable(mainGroupCode),
        // uncomment the code below to set everything to expanded === true
        // but not the subtotals
        // expanded:
        //   mainGroupCode === "03" ||
        //   mainGroupCode === "05" ||
        //   mainGroupCode === "08" ||
        //   mainGroupCode === "09"
        //     ? false
        //     : true,
        group: mainGroupCode,
        header: {
          title: title,
          list: values.map((e) => {
            return groups[e].header.title.replace(
              /[0-9].(a|b)/g,
              groups[e].group.substring(0, groups[e].group.length - 1)
            );
          }),
        },
        elements: [
          {
            list: [],
            title: 0,
          },
          {
            list: [],
            title: 0,
          },
          {
            list: [],
            title: 0,
          },
        ],
      },
    };
  }, {} as Record<string, ParsedData>);

  for (const key in mainGroups) {
    const mainGroupKey = `${mainGroups[key].group} ${mainGroups[key].header.title}`;
    const subGroups = koondvaadeMainGroups[mainGroupKey];
    subGroups.forEach((e) => {
      const subGroup = groups[e];

      for (let i = 0; i < 3; i++) {
        mainGroups[key].elements[i].list.push(subGroup.elements[i].title);
        mainGroups[key].elements[i].title = mainGroups[key].elements[
          i
        ].list.reduce((a: number, curr: number) => a + curr, 0);
      }
    });
  }

  const data = Object.keys(mainGroups)
    .sort((a, b) => parseFloat(a) - parseFloat(b))
    .map((key) => mainGroups[key]);

  // totalBudget = -kuludTotal

  const tuludChartData = getChartData({
    config: koondvaadeTuludChart,
    groups,
    turnValuesToNegative: true,
  });

  const kuludChartData = getChartData({
    config: koondvaadeKuludChart,
    groups,
    turnValuesToNegative: false,
  });

  const tuludTotals = getTotalValues(tuludChartData);
  const kuludTotals = getTotalValues(kuludChartData);
  return {
    data: data,
    tuludChartData: tuludChartData,
    tuludTotal: tuludTotals.total,
    tuludCurrentTotal: tuludTotals.currentTotal,
    kuludChartData: kuludChartData,
    kuludTotal: kuludTotals.total,
    kuludCurrentTotal: kuludTotals.currentTotal,
    selectedDate: csvData.selectedDate,
  };
};

const isExpandable = (code: string): boolean =>
  ["01", "02", "04", "06", "07"].includes(code);

const getChartData = (params: {
  config: string[];
  groups: Record<string, ParsedData>;
  turnValuesToNegative: boolean;
}): TotalsByCategory[] => {
  const { config, groups, turnValuesToNegative } = params;

  return config.map((e) => {
    return {
      type: groups[e].header.title.replace(
        /[0-9].(a|b)/g,
        groups[e].group.substring(0, groups[e].group.length - 1)
      ),
      totalValue:
        turnValuesToNegative && groups[e].elements[0].title < 0
          ? -groups[e].elements[0].title
          : groups[e].elements[0].title,
      currentValue:
        turnValuesToNegative && groups[e].elements[1].title < 0
          ? -groups[e].elements[1].title
          : groups[e].elements[1].title,
    };
  });
};
