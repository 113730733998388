import {
  ExpandedConfig,
  ParsedData,
  TotalsByCategory,
} from "@/firebase/parse/types";
import { DeepReadonly } from "vue";
import { CsvImport } from "@/common/types";

export const getTotalValues = (
  data: TotalsByCategory[]
): { total: number; currentTotal: number } =>
  data.reduce(
    (acc, curr) => ({
      total: acc.total + curr.totalValue,
      currentTotal: acc.currentTotal + curr.currentValue,
    }),
    { total: 0, currentTotal: 0 }
  );

export const getGroups = (params: {
  csvData: DeepReadonly<CsvImport>;
  config: Record<string, string[]>;
  showArticleFeatures: boolean;
}): Record<string, ParsedData> => {
  const { csvData, config, showArticleFeatures } = params;
  const { dataPoints } = csvData;
  const expandedConfig: ExpandedConfig[] = [];

  const groups: Record<string, ParsedData> = {};
  // TODO: make utility
  for (const key in config) {
    config[key].forEach((subGroup) => {
      const mainGroupCode = key.split(" ")[0];
      expandedConfig.push({
        subGroupCode: subGroup,
        mainGroupCode,
        mainGroupTitle: key,
      });

      groups[mainGroupCode] = {
        expandable: true,
        group: mainGroupCode,
        header: {
          title: "",
          list: [],
        },
        elements: [],
      };

      dataPoints.forEach(() => {
        groups[mainGroupCode].elements.push({
          title: 0,
          list: [],
        });
      });
    });
  }

  csvData.rows
    .filter((rf) => rf.a || rf.ta)
    .forEach((r) => {
      const groupKey = r.a !== "" ? r.a.trim() : r.ta.trim();

      expandedConfig.forEach((e) => {
        if (groupKey === e.subGroupCode) {
          groups[e.mainGroupCode].header.title = showArticleFeatures
            ? e.mainGroupTitle
            : e.mainGroupTitle.replace(
                `${e.mainGroupTitle.split(" ")[0]} `,
                ""
              );

          dataPoints.forEach((kk: string, index: number) => {
            groups[e.mainGroupCode].elements[index].title = parseFloat(
              r[kk].replace(",", ".")
            );
          });

          const headerItem = showArticleFeatures
            ? `${e.subGroupCode} - ${r.nimi?.trim()}`
            : r.nimi?.trim();
          groups[e.mainGroupCode].header.list.push(headerItem);

          dataPoints.forEach((kk: string, index: number) => {
            groups[e.mainGroupCode].elements[index].list.push(
              parseFloat(r[kk].replace(",", "."))
            );
          });
        }
      });

      for (const key in groups) {
        groups[key].elements.forEach(
          (e) => (e.title = e.list.reduce((a, curr) => a + curr, 0))
        );
      }
    });
  return groups;
};

export const getParsedData = ({
  groups,
  hideZeroes,
}: {
  groups: Record<string, ParsedData>;
  hideZeroes: boolean;
}): ParsedData[] => {
  return Object.keys(groups)
    .sort((a, b) => parseFloat(a) - parseFloat(b))
    .map((key) => groups[key])
    .filter((v) => (hideZeroes ? v.header.title !== "" : v.header.title));
};
