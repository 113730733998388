import { db } from "@/firebase/firebase";
import { unPackRef, unPackRefs } from "@/firebase/utils";
import { MunicipalityDTO } from "@/common/types";
import { useAppStore } from "@/store/AppStore";

const appStore = useAppStore();
const COLLECTION = "municipalities";

export const getData = <T>({
  collection,
  document,
}: {
  collection: string;
  document: string;
}): Promise<T | null> => {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    db.collection(collection)
      .doc(document)
      .get()
      .then((docRef) => resolve(unPackRef(docRef)))
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
};

export const getAllMunicipalities = (): Promise<MunicipalityDTO[]> => {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    db.collection(COLLECTION)
      .get()
      .then((municipalitiesRef) =>
        resolve(unPackRefs<MunicipalityDTO>(municipalitiesRef))
      )
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
};
