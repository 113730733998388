import {
  AgreementApplicationInput,
  ContactInput,
  CreateUserInput,
  FunctionName,
} from "@/firebase/functions/types";
import { functions } from "@/firebase/firebase";
import { CsvImport } from "@/common/types";
import { useAppStore } from "@/store/AppStore";

const appStore = useAppStore();

export function createUserFun(data: CreateUserInput): Promise<void> {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const createUser = functions.httpsCallable(FunctionName.CREATE_USER);
    createUser(data)
      .then(() => resolve())
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
}

export function addInvesteeringudFun(data: CsvImport): Promise<void> {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const addInvesteeringud = functions.httpsCallable(
      FunctionName.ADD_INVESTEERINGUD
    );
    addInvesteeringud(data)
      .then(() => resolve())
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
}

export function addEelarveandmikFun(data: CsvImport): Promise<void> {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const addEelarveandmik = functions.httpsCallable(
      FunctionName.ADD_EELARVEANDMIK
    );
    addEelarveandmik(data)
      .then(() => resolve())
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
}

export function addAgreementApplicationFun(
  data: AgreementApplicationInput
): Promise<void> {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const addAgreementApplication = functions.httpsCallable(
      FunctionName.ADD_AGREEMENT_APPLICATION
    );
    addAgreementApplication(data)
      .then(() => resolve())
      .catch((e) => reject(e))
      .finally(() => appStore.removeLoading(loadingID));
  });
}

export function addContactFun(data: ContactInput): Promise<void> {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const addContact = functions.httpsCallable(FunctionName.ADD_CONTACT);
    addContact(data)
      .then(() => resolve())
      .catch((e) => reject(e))
      .finally(() => appStore.removeLoading(loadingID));
  });
}
